<template>

  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    class="h-100"
  >
    <b-col
      cols="12"
    >
      <b-card
        no-body
        class="card-table h-100"
      >
        <b-card-header class="w-100">
          <b-card-title>Programs Display Order</b-card-title>
        </b-card-header>

        <b-card-body class="my-1">
          <b-row class="d-flex align-items-center">
            <b-col
              cols="12"
              md="6"
            >
              <label class="font-weight-bold">Sort By</label>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sortByOptions"
                :value="sortBy"
                :clearable="false"
                :reduce="option => option.value"
                @input="updateSortBy"
              />
            </b-col>
          </b-row>
        </b-card-body>

        <b-tabs
          v-model="tabIndex"
          content-class="col-12 mt-1 mt-md-0"
          pills
          fill
          nav-wrapper-class="col-12 py-1 px-0 border-bottom  bg-white"
          nav-class="mb-0 bg-light text-center"
        >
          <b-tab
            v-for="(instance, index) in instances"
            :key="index"
          >
            <template #title>
              <span class="font-weight-bold">{{ instance.name }}</span>
            </template>
            <draggable
              v-model="programs"
              tag="ul"
              handle=".handle-drag"
              class="list-group list-group-flush"
              @end="hasUnsavedData = true"
            >
              <b-list-group-item
                v-for="(program, index) in programs"
                :key="index"
              >
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex">
                      <img
                        src="@/assets/images/icons/more-vertical-v2.svg"
                        class="handle-drag cursor-move mr-1"
                      >
                      {{ program.name }}
                    </div>

                  </b-col>
                </b-row>
              </b-list-group-item>
            </draggable>
            <div
              v-if="!programs.length"
              class="no-results py-2"
            >
              <h5>No Items Found</h5>
            </div>
          </b-tab>
        </b-tabs>

        <b-card-footer>
          <div
            v-if="instances[tabIndex]"
            class="d-flex justify-content-end my-1"
          >
            <b-button
              :disabled="!programs.length || !hasUnsavedData"
              variant="primary"
              class="mr-2"
              @click="saveOrdering"
            >
              Save Order of Programs for {{ instances[tabIndex].name }}
            </b-button>
          </div>
        </b-card-footer>

      </b-card>
    </b-col>
  </b-overlay>

</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardFooter,
  BCol,
  BListGroupItem,
  BOverlay,
  BRow,
  BFormCheckbox,
  BButton,
  BTabs,
  BTab,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import store from '@/store'
import storeModule from '@/views/admin/settings-programs/settingsProgramsStoreModule'
import vSelect from 'vue-select'

export default {
  name: 'SettingsPrograms',
  components: {
    BButton,
    BFormCheckbox,
    BRow,
    BListGroupItem,
    BCol,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BOverlay,
    BCardTitle,
    BCard,
    BTabs,
    BTab,

    vSelect,
    draggable,
  },
  data() {
    return {
      tabIndex: 0,
      sortByOptions: [
        { label: 'Dashboard Position', value: 'dashboard_position' },
        { label: 'Created Date', value: 'created_at' },
        { label: 'Start Date', value: 'date_start' },
        { label: 'Name', value: 'name' },
      ],
      hasUnsavedData: false,
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-settings-programs'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
  },
  computed: {
    ...mapGetters({
      isLoading: 'app-settings-programs/getIsLoading',
      // sortBy: 'app-settings-programs/getSortBy',
      instances: 'app-settings-programs/getInstancesList',
    }),
    sortBy: {
      get() {
        return store.getters['app-settings-programs/getSortBy']
      },
      set(val) {
        store.commit('app-settings-programs/SET_SORT_BY', val)
      },
    },
    programs: {
      get() {
        return store.getters['app-settings-programs/getProgramsList']
      },
      set(val) {
        store.commit('app-settings-programs/SET_PROGRAMS_LIST', val)
      },
    },

  },
  watch: {
    sortBy(val, prevVal) {
      if (val !== prevVal) {
        this.prepareProgramsList()
      }
    },
    tabIndex(val) {
      if (val >= 0) {
        store.commit('app-settings-programs/SET_SELECTED_INSTANCE', this.instances[val])
        this.hasUnsavedData = false
        if (this.sortBy !== 'dashboard_position') {
          this.sortBy = 'dashboard_position'
        } else {
          this.prepareProgramsList()
        }
      }
    },
  },
  async created() {
    await this.prepareInstanceList()
    store.commit('app-settings-programs/SET_SELECTED_INSTANCE', this.instances[this.tabIndex])
    await this.prepareProgramsList()
  },
  methods: {
    async prepareInstanceList() {
      await this.$store.dispatch('app-settings-programs/fetchInstancesList')
    },
    async prepareProgramsList() {
      await this.$store.dispatch('app-settings-programs/fetchProgramsList')
    },
    updateSortBy(val) {
      if (val !== 'dashboard_position') {
        this.hasUnsavedData = true
      }
      store.commit('app-settings-programs/SET_SORT_BY', val)
    },
    async saveOrdering() {
      await this.$store.dispatch('app-settings-programs/saveProgramsPositions')
      this.hasUnsavedData = false
      if (this.sortBy !== 'dashboard_position') {
        this.sortBy = 'dashboard_position'
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
