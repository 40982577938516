import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    sortBy: 'dashboard_position',
    onlyActive: false,
    programsList: [],
    instancesList: [],
    selectedInstance: null,
  },
  getters: {
    getIsLoading: state => state.isLoading,
    getProgramsList: state => state.programsList,
    getInstancesList: state => state.instancesList,
    getSortBy: state => state.sortBy,
    getSelectedInstance: state => state.selectedInstance,
  },
  mutations: {
    SET_IS_LOADING: (state, val) => {
      state.isLoading = val
    },
    SET_PROGRAMS_LIST: (state, val) => {
      state.programsList = val
    },
    SET_INSTANCES_LIST: (state, val) => {
      state.instancesList = val
    },
    SET_SORT_BY: (state, val) => {
      state.sortBy = val
    },
    SET_SELECTED_INSTANCE: (state, val) => {
      state.selectedInstance = val
    },
  },
  actions: {
    async fetchInstancesList({ state, commit }) {
      try {
        commit('SET_IS_LOADING', true)
        const queryParams = {}

        const response = await axios.get('/auth/schools', {
          params: queryParams,
        })

        const instances =  response.data.data
        commit('SET_IS_LOADING', false)
        commit('SET_INSTANCES_LIST', instances)
        commit('SET_SELECTED_INSTANCE', instances[0])
        return response
      } catch (error) {
        return error
      }
    },
    async fetchProgramsList({ state, commit }) {
      try {
        commit('SET_IS_LOADING', true)
        const queryParams = {
          sortBy: state.sortBy ?? 'position',
          school_id: state.selectedInstance.id,
          without_filter_by_current_user: 1,
        }

        const response = await axios.get('/auth/programs', {
          params: queryParams,
        })
        const programs = response.data.data.sort((a, b) => a.position - b.position)
        commit('SET_IS_LOADING', false)
        commit('SET_PROGRAMS_LIST', programs)
        return response
      } catch (error) {
        return error
      }
    },
    async saveProgramsPositions({ state, commit }, vm) {
      try {
        commit('SET_IS_LOADING', true)
        const queryParams = {
          ids: state.programsList.map(item => item.id),
          school_id: state.selectedInstance.id,
        }
        const response = await axios.post('/auth/programs/update/dashboard-positions', queryParams)
        commit('SET_IS_LOADING', false)
        return response
      } catch (error) {
        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong, please refresh the page',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return error
      }
    },
  },
}
